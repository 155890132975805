import {useState} from 'react';

function App() {
  const [state, setState] = useState(0);
  const onClick = () => {
      setState(state + 1);
  }
  return (
    <div className="App">
      <p>Вы кликнули: {state} раз</p>
      <button onClick={() => onClick()}>жми пидор</button>
    </div>
  );
}

export default App;
